import axios from 'axios';
import { apiHeadersFormData, NGROK_URL } from 'utils/constants';


const axiosFormClient = axios.create();

axiosFormClient.defaults.baseURL = NGROK_URL;

// Important: If axios is used with multiple domains, the AUTH_TOKEN will be sent to all of them.
// See below for an example using Custom instance defaults instead.
// axiosClient.defaults.headers.common['Authorization'] = AUTH_TOKEN;

axiosFormClient.defaults.headers = apiHeadersFormData;
// axiosClient.defaults.withCredentials = true;

export function getFormRequest(URL) {
  return axiosFormClient.get(`/${URL}`).then((response) => response);
}

export function postFormRequest(URL,payload) {
  return axiosFormClient.post(`/${URL}`,payload).then((response) => response);
}