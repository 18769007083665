import axios from 'axios';
import { apiHeaders, NGROK_URL } from 'utils/constants';

const axiosFileClient = axios.create();

axiosFileClient.defaults.baseURL = NGROK_URL;
axiosFileClient.defaults.responseType = 'blob';

// Important: If axios is used with multiple domains, the AUTH_TOKEN will be sent to all of them.
// See below for an example using Custom instance defaults instead.
// axiosClient.defaults.headers.common['Authorization'] = AUTH_TOKEN;

axiosFileClient.defaults.headers = apiHeaders;
// axiosClient.defaults.withCredentials = true;

export function getRequestFile(URL) {
  return axiosFileClient.get(`/${URL}`).then((response) => response);
}