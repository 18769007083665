import React from 'react';
import { Switch, Route } from 'react-router-dom';
import NotFoundPage from '../NotFoundPage/Loadable';
import DocumentDetails from '../DocDetails';

const Routes = ({stories}) => {
  return (
    <>
      <Switch>
        {/* <Route exact path="/" component={DocumentDetails} /> */}
        <Route exact path="/"
          render={(props) => <DocumentDetails stories={stories} {...props} />}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </>
  );   
}

export default Routes;