import React, { useEffect, useState } from "react";
import "components/FileUploadDialog/fileUpload.module.scss";
import Images from "assets/asset_imports";
import {Backdrop,Modal, makeStyles} from "@material-ui/core";
import { connect } from 'react-redux';
import { doFileUpload } from 'views/DocDetails/docDetailsActions';

const FileUploadModal = (props) => {
    const { open, docType, sendSuccessFailureCallback, isLoading, isFileUploaded, onClose, onUpload, fileUploadError } = props;
    const accordionStyles = makeStyles((theme) => ({
        backdrop: {
          zIndex: 10,
          color: "#fff",
        }
    }));
    const classes = accordionStyles();
    const [fileMessage, setFileMessage] = useState(0);
    const [fileLength,setFileLength] = React.useState(0);
    const [invoices,setInvoices] = React.useState(false);
    const [isSent,setIsSent] = React.useState(false); 
    
    useEffect(() => {
      if(isFileUploaded && !isSent){
        setIsSent(true);
        handleClose(isFileUploaded);
      }
      if(fileUploadError){
        setFileLength(0)
        setInvoices(false);
      }
    }, [props]);

    const handleClose = (flag) => {
      setFileLength(0)
      setInvoices(false);
      onClose(flag);
    }

      const uploadInvoice = () => {
        if(invoices){
          onUpload(invoices);       
        }else{
          setFileMessage("Please select a file to proceed.")
        }  
      }

      const addInvoice = (event) => {
        let fileData = null;
        if (event.target.files.length) {
          fileData = event.target.files;
    
          setFileLength(fileData.length)
    
          const formData = new FormData();
    
          for (let i = 0; i < fileData.length; i++) {
            formData.append("file", fileData[i])
          }
          formData.append("file_type", docType);
          setInvoices(formData)
          setFileMessage("");
        }
      };

      const dropHandler = (ev) => {
        ev.preventDefault();
        let filesSet = [];
        if (ev.dataTransfer.items) {
          for (var i = 0; i < ev.dataTransfer.items.length; i++) {
            if (ev.dataTransfer.items[i].kind === 'file') {
              var file = ev.dataTransfer.items[i].getAsFile();
              filesSet.push(file);
            }
          }
        } else {
          for (var i = 0; i < ev.dataTransfer.files.length; i++) {
            filesSet.push(ev.dataTransfer.files[i]);
          }
        }
        const formData = new FormData();
    
        for (let i = 0; i < filesSet.length; i++) {
          formData.append("file", filesSet[i])
        }
        formData.append("file_type", docType);
        formData.append("ogi", ogi);
        setInvoices(formData);
        setFileLength(filesSet.length);
        setFileMessage("");
      }
    
      function dragOverHandler(ev) {
        ev.preventDefault();
      }

  return (
    <Backdrop
      className={classes.backdrop}
      open={open}>
        <Modal
          open={open}
          style={{ display:'flex',justifyContent:'center',alignItems:'center'}}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">

          <div style={{ backgroundColor: '#FFFFFF',width:520,height:250,borderRadius:6 }} align="center">
          <>
          {
            !isLoading?
            <>   
            <img src={Images.close} onClick={()=>handleClose(null)} 
              style={{cursor:'pointer',width:19,height:19,marginTop:12,marginLeft:'94%',marginTop:6}}/>
              <img src={Images.upload} style={{width:35,height:33,marginTop:12}}/>
            <div align="center" 
              id="drop_zone"
              onDrop={dropHandler} 
              onDragOver={dragOverHandler}
            >
                <div style={{ color: '#1C5784',font:'normal normal bold 20px/26px Open Sans' ,letterSpacing:'0px',opacity:1}}>
                  Drop files here
                </div>

                <div style={{ color: '#1C5784',font:'normal normal bold 15px/26px Open Sans',opacity:1,letterSpacing:0, marginTop:10 }}>
                  or click here
                  <span style={{background: '#1177FD 0% 0% no-repeat padding-box',borderRadius:5,marginLeft:4,padding:8}}>

                    <label
                      htmlFor="contained-button-file"
                      style={{cursor:'pointer'}}>
                    <span style={{font:'normal normal 600 15px/26px Open Sans',textAlign:'center',letterSpacing:0,opacity:1,color:'#fff'}}>Choose files</span>
                  </label>
                  <input
                    multiple
                    className="uploadBtn"
                    id="contained-button-file"
                    style={{ display: "none" }}
                    type={"file"}
                    accept="image/*,application/pdf"
                    onChange={addInvoice}/>
                  </span>
                </div>
                <div>
                  {
                    fileLength &&
                    <>
                    <span style={{color: '#1C5784',font:'normal normal bold 10px/26px Open Sans',letterSpacing:0,opacity:1}}>
                    {fileLength} Files uploaded  
                    </span>
                    <i className="fa fa-check-circle ml-1" style={{fontSize:'12px',color:'#40FF7F'}}></i>
                    </>
                  }
                </div>
            
                <button
                  onClick={uploadInvoice}
                  className="btn btn-lg sign-btn get-btn mt-4"
                  style = {{
                      display: 'flex',
                      alignContent: 'center',
                      justifyContent: 'center',
                      background: '#00F8F1 0% 0% no-repeat padding-box',
                      boxShadow: '0px 3px 6px #14141429',
                      borderRadius: 3, opacity: 1, width: 142 
                    }}
                  >
                  Done
                </button>
                {fileMessage && <a className="text-danger mt-1" style={{fontSize:'600'}}>{fileMessage}</a>}
              </div>
            </>:
            <div className="col-12">
            <div className="d-flex justify-content-center align-items-center" style={{height:'200px'}}>
               <svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle className="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
              </svg>
            </div>
            <h6 style={{color:'#011B3E',fontSize:'14px'}}>Please wait while your files upload.</h6>
            </div>
          }
          </>
        </div>
      </Modal>
    </Backdrop>
  );
}

const mapStateToProps = state => ({
  isFileUploaded: state.docDetailsState.fileUploadData,
  isLoading: state.docDetailsState.isUploadLoading,
  fileUploadError: state.docDetailsState.fileUploadError
});
 
const mapDispatchToProps = dispatch => ({
  onUpload: fileData => dispatch(doFileUpload(fileData))
});

export default connect(mapStateToProps,mapDispatchToProps)(FileUploadModal);