import React from 'react'; 
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const SnackbarComponent = ({isOpen,message,color,handleClose}) => 
    <Snackbar open={isOpen} onClose={handleClose}
        autoHideDuration={4000} anchorOrigin={{ vertical:'top', horizontal:'right' }}>
        <Alert severity={color}>{message}</Alert>
      </Snackbar>

export default SnackbarComponent;