import React from 'react';
import $ from "jquery";
import { jsPlumb } from 'jsplumb';
import 'views/DocDetails/documentDetails.module.scss';
import Images from 'assets/asset_imports';
import axios from 'axios';
import {Tooltip,makeStyles} from '@material-ui/core';
import SnackbarComponent from 'components/SnackbarComponent';
import { PNOMAD_BASE_URL, NGROK_URL } from 'utils/constants';
import FileUploadModal from 'components/FileUploadDialog';
import EditCustomKeyDialog from 'components/EditCustomKeyDialog';
import SelectExportDialog from 'components/SelectExportDialog';
import { doArchiveStory } from 'actions/archive';
import { connect } from 'react-redux';
import { doFetchStories } from 'actions/addStories';
import { doFetchDocument, doFetchDataCoordinates, doSubmitInvoice } from 'views/DocDetails/docDetailsActions';
import Spinner from 'components/Spinner';
import toast from 'react-hot-toast';

var demoResponse;

const styles = theme => ({
  backdrop: {
    zIndex: 10,
    color: '#fff',
  }
});

var svgWidth = 870;
var svgHeight = 1150;

const useStylesBootstrap = makeStyles((theme) => ({
arrow: {
  color: theme.palette.common.black,
},
tooltip: {
  backgroundColor: theme.palette.common.black,
},
}));

function BootstrapTooltip(props) {
const classes = useStylesBootstrap();
return <Tooltip classes={classes} placement="top" {...props} arrow/>
}

const nodeStyle = { fill : "transparent", radius: 6, outlineStroke:"green", outlineWidth:20, strokeWidth:5 };

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fileId: 0,
      showUploadModal: false,
      toastColor:"success",
      successMsg:"",
      snackOpen:false,
      valueFocussed:null,
      editChangeCount:0,
      isCustomDeleted:false,
      showInvoiceDetails: true,
      showAmounts: false,
      pageValue: 1,
      isLoading: false,
      previewImg: [],
      invoiceImage: '',
      invoiceDetailsMap: '',
      boundsData: '',
      leftSourceId: 1,
      leftSourceIndex: 0,
      authenticationToken: '',
      dialogOpen: false,
      newModelDialogOpen:false,
      showLineItems: false,
      lineTable: false,
      userEmail: "",
      showLogoutBtn: false,
      lineItemslist: [],
      zoomFactor: 1,
      vendorName: "",
      fileName: "",
      deleteHovered: false,
      invalidInvoice: false,
      leftKeys: this.props.leftKeys,
      rightKeys: this.props.rightKeys,
      customLeftKeys: this.props.customLeftKeys,
      linking: this.props.linking,
      customName: this.props.customName,
      leftKeyEditId: null,
      inputFocused: false,
      customRightKeys: this.props.customRightKeys,
      deletedLeftKeys:this.props.deletedLeftKeys,
      selectedLeftKey:null,
      selectedCustomLeftKey:null,
      focusRightKey:null,
      customInputFocused:null,
      customLeftKeyText:"",
      totalRows:"0", 
      inputFocusCustom:false,
      lineItemTableData: this.props.lineItemTableData||[],
      maxRows:0,
      activeSection:"",
      newModel:'',
      modalMenuOpen:null,
      selectedModel:null,
      alteredKey:null,
      tableSortedData: this.props.tableSortedData,
      exportFormat: "excel",
      isApproved:false,
      showEditPopupKey: null,
      leftKeyEditValue: null,
      leftKeyEditKey:null,
      popUpCoords: null,
      avatar: "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    }
    this.globalLeftClickedKey = null;
    this.leftKeysArr = [];
    this.connection = null;
    this.docTopOffset = 0;
    this.sourceList = [];
    this.bodyHeight = $("body").height();
  }
  
  componentDidMount() {
    this.props.onFetchStories("Redux");
    $("body").css("overflow", "hidden");
    this.jsPlumbInstance = jsPlumb.getInstance({
      Container: $("#drag_canvas"),
      ConnectionsDetachable: false,
      Anchor:["RightMiddle","LeftMiddle"],
      EndpointStyle : { fill : "transparent", radius: 0 },
      Connector: ["Straight", { stub: [35, 0], gap: [6,6], cornerRadius: 10, alwaysRespectStubs: false }],
      PaintStyle: {
          strokeWidth: 3,
          stroke: "green",
          transition:'.2s'
      }   
    });
    this.handleResolutionChange(this);
  }

  componentWillUnmount(){
    if(this.jsPlumbInstance){
      this.jsPlumbInstance.deleteEveryEndpoint();
      this.jsPlumbInstance = null;
    }
    $(document).off('mousedown');
    $(document).off('mousemove');
    $(document).off('mouseup');
    $("body").css("overflow-x", "unset");
  }

  handleHeaderFloat = () => {
    window.onscroll = function() {
      var header = document.getElementById("detailHeader");
      var sticky = header.offsetTop;
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
    };
  }

  handleFocusRow = (row) => {
    let tableSet = this.state.tableSortedData;
    var elemt = document.getElementById(row.table_data_id);
    if(elemt){
      var elementTop = String(elemt.style.top);
      var percFigure = elementTop.replace("%","");
      var percVal = ((this.bodyHeight * percFigure)/100 ).toFixed(3);
      let calculatedTopOffset = Number(percVal) + 1150*(Number(row.page)-1);
      $("#doc_overflow").scrollTop(calculatedTopOffset);
      elemt.style['border'] = '2px solid blue';
  
      for(let i=0; i<this.state.rightKeys.length; i++){
        if(tableSet[i]){
          for(let j=0; j<tableSet[i].length; j++){
            for(let k=0; k<tableSet[i][j].length; k++){
              let instantElement = document.getElementById(tableSet[i][j][k].table_data_id);
              if(row.table_data_id !== tableSet[i][j][k].table_data_id){
                instantElement.style['border'] = 'none';
              }
            }
          }
        }
      }
    }
  }

  handleDrawBox = (self) => {
    $(function() {
      var widget;
      var x;
      var y;
      var finX;
      var finY;
      var ismousedown = false;
      var coords = {};
      var allowDraw = false;
      var incidentPage = 0;

      $(document).on({
        mousedown: function(event) {
          incidentPage = Number($(event.target).attr('title'))+1;
          if ($(event.target).attr('class') == 'formwrapper') {
            x = event.pageX;
            y = event.pageY;
            coords.x1 = event.offsetX;
            coords.y1 = event.offsetY;
            $('body').append('<div class="widget" style="top:' + y + 'px; left: ' + x + 'px;"></div>');
            widget = $('.widget').last();
            ismousedown = true;
          }
        },
        mousemove: function(event) {
          if (ismousedown == true) {
            finX = event.pageX;
            finY = event.pageY;
            coords.height = (finY - y)+4;
            coords.width = (finX - x)+4;
            coords.x2 = coords.x1 + (finX - x)+4;
            coords.y2 = coords.y1 + (finY - y)+4;

            if(finX > x||finY > y){
              allowDraw = true;
            }
            
            widget.width(finX - x);
            widget.height(finY - y);
            widget.css({
              'width': (finX - x) + '!important',
              'height': (finY - y) + '!important',
              'display': 'block',
              'border': '2px dashed #ccc'
            });
          }
        },
        mouseup: function(event) {
          if (ismousedown == true) {
          ismousedown = false;
          widget.css({
            'background': '#222',
            'border': '0',
            'cursor': 'move'
          });

          $('.widget').remove();
            if(allowDraw){
              self.fetchCoordinatesValue(coords,incidentPage);
              widget = null;
              x = null;
              y = null;
              finX = null;
              finY = null;
              ismousedown = false;
              coords = {};
              allowDraw = false;
            }
          }
        }
    
      });
    });
  }

  setupTableData = () => {
    var multipageTableData = [];
    var maxRows=0;

    for(let i=0; i<this.state.rightKeys.length ; i++) { //Page loop

      var headerSet = [] , rowCount = 0;
      //  maxRows=0;

      for(let j = 0; j < this.state.rightKeys[i].length; j++) {
        if(this.state.rightKeys[i][j].table_data == true) {
          var header = this.state.rightKeys[i][j].key.replace("line_item/", "")
          if(!headerSet.includes(header)) {
            headerSet.push(header)
          }
        }
        if(rowCount < this.state.rightKeys[i][j].row) {
          rowCount = this.state.rightKeys[i][j].row;
        }
      }
      var tableData = [];
      for(let k = 1; k <= rowCount; k++) {

        var rowData = [];
        for(let l = 0; l < headerSet.length; l++) {

          var cellData = {};
          cellData.heading = headerSet[l];
          for(let m = 0; m < this.state.rightKeys[i].length; m++) {
            if(this.state.rightKeys[i][m].table_data===true){
            var header = this.state.rightKeys[i][m].key.replace("line_item/", "");
            var row = this.state.rightKeys[i][m].row;
            if((headerSet[l] == header) && (k == row)) {
              cellData.value = this.state.rightKeys[i][m].value;
              cellData.key = this.state.rightKeys[i][m].pnomad_key;
            } else {
              if(!cellData.value) {
                cellData.value = "";
              }
            }
          }
          }
          rowData.push(cellData);
        }
        tableData.push(rowData);
        maxRows = maxRows+1;
      }
      multipageTableData.push(tableData)
    }
    this.setState({
      lineItemTableData:multipageTableData,
      maxRows});
  }

  fetchCoordinatesValue = async(coord, pageNumber) => {
    this.setState({
      isLoading: true
    });
      var date = new Date();
      const boundingBox = {
        "x1": (coord.x1/svgWidth),
        "y1": (coord.y1/svgHeight),
        "x2": (coord.x2/svgWidth),
        "y2": (coord.y1/svgHeight),
        "x3": (coord.x2/svgWidth),
        "y3": (coord.y2/svgHeight),
        "x4": (coord.x1/svgWidth),
        "y4": (coord.y2/svgHeight),
      }
      const rightKey = {
        "PnomadRightkey":`PnomadNewRightkey${date.getTime()}_${pageNumber}`,
        "value": "",
        "table_data": false,
        "custom_box":true,
        "page_number": pageNumber,
        "boundingBoxes":[boundingBox]
      }
      this.addNewKey(null,`PnomadNewRightkey${date.getTime()}_${pageNumber+1}`);
      this.props.onExtractDataCoordinate({rightKey, file_id: this.props.uploadedFileData.file_id});
  }

  handleResolutionChange = (self) => {
    $(window).resize(function() {
      if(self.connection && self.jsPlumbInstance){
        if (self.connection.source && self.connection.source ) {
            self.jsPlumbInstance.deleteConnection(self.connection);
        }
      }
    });
  }

  deleteConnection = () => {
    if(this.jsPlumbInstance){
      this.jsPlumbInstance.deleteConnection(this.connection);
    }
  }

  submitInvoice = async() => {
    var downloadTimeout = null;
    clearTimeout(downloadTimeout);
    this.setState({
      isLoading: true
    });
    var clkTemp = [...this.state.customLeftKeys];
    clkTemp = clkTemp.map(item => ({...item,"PnomadLeftkey":item.pnomad_key}))
    let reqBody = {
      "file_id": this.props.uploadedFileData.file_id,
      "data": {
        "linking": this.state.linking,
        "custom_name": this.state.customName,
        "customLeftKeys": clkTemp,
        "customRightKeys": this.state.customRightKeys,
        "deletedLeftKeys": this.state.deletedLeftKeys,
        "table_data": this.state.tableSortedData
      }
    }
    this.props.onInvoiceSubmit({reqBody, format: this.state.exportFormat, fileName: this.props.fileName});
  }

  getDocumentData = async() => {
    this.setState({isLoading: true})
    let reqBody = { file_id: this.props.uploadedFileData.file_id }

    //-------------------- fetch google vision response api ---------------------------------
    axios({
        method: 'post',
        url: `${NGROK_URL}/extract_data/`,
        data: reqBody
      })
      .then(resp => {
              var temp = [];
              for(let i = 0 ; i < resp.data.data.right.length ; i++ ){
                temp.push([])
              }
              if(resp.data.data.left[0].length>0 && resp.data.data.right[0].length>0){
              this.setState({
                isLoading: false,
                leftKeys: resp.data.data.left,
                rightKeys: resp.data.data.right,
                customLeftKeys: resp.data.data.customLeftKeys ? resp.data.data.customLeftKeys : [], // addKey not exists in response
                customName: resp.data.data.custom_name,
                linking: resp.data.data.linking,
                tableSortedData : resp.data.data.table_data,
                customRightKeys:resp.data.data.customRightKeys && resp.data.data.customRightKeys.length>0?resp.data.data.customRightKeys:temp,
                deletedLeftKeys: resp.data.data.deletedLeftKeys && resp.data.data.deletedLeftKeys.length>0?resp.data.data.deletedLeftKeys:[]
              }, () => {
                this.jsPlumbReady(this);
              })
            }else{
              this.setState({
                isLoading:false,
                invalidInvoice:true
              });
            }
          })
            .catch(error => {
              if (error.response) {
                if(error.response.data && error.response.data.non_field_errors){
                    this.setState({
                      successMsg:error.response.data.non_field_errors[0],
                      snackOpen:false,
                      toastColor:'error'
                    });
                }
              }
            })
          }

  jsPlumbReady = (self) => {
    jsPlumb.ready(function() {
      if(self.jsPlumbInstance){
        for (let i = 0; i < self.state.leftKeys.length; i++) {
          for (let j = 0; j < self.state.leftKeys[i].length; j++) {
            //For Left Node end points
            if (!self.state.leftKeys[i][j].table_data && !self.isLeftKeyDeleted(self.state.leftKeys[i][j].pnomad_key)) {
               self.jsPlumbInstance.addEndpoint(self.state.leftKeys[i][j].pnomad_key,{   
                  endpoint:'Dot',
                  enabled: false,
                  anchor:["RightMiddle"]
                });
            }
          }
        }
  // left custom nodes-------------
          for (let i = 0; i < self.state.customLeftKeys.length; i++) {
              if (!self.isLeftKeyDeleted(self.state.customLeftKeys[i].pnomad_key)) {
                self.jsPlumbInstance.addEndpoint(self.state.customLeftKeys[i].pnomad_key,{
                  endpoint:'Dot',
                  anchor:["RightMiddle"]
                })
              }
          }
          
  // right nodes-------------
        for (let k = 0; k < self.state.rightKeys.length; k++) {
          for (let l = 0; l < self.state.rightKeys[k].length; l++) {
           self.jsPlumbInstance.addEndpoint(self.state.rightKeys[k][l].pnomad_key,{
              endpoint:'Dot',
              anchor:["LeftMiddle"]
            })
          }
        }

        for (let m=0; m < self.state.customRightKeys.length; m++) {
          for (let n=0; n < self.state.customRightKeys[m].length; n++) {
            self.jsPlumbInstance.addEndpoint(self.state.rightKeys[m][n].pnomad_key,{
              endpoint:'Dot',
              anchor:["LeftMiddle"]
            })
          }
        }
    
        // Events
        self.jsPlumbInstance.bind('connection', function(jsPlumbConnection) {

            var addSelFieldBG = function(connection) {
                $('#' + connection.sourceId).addClass('row-hover-background');
                $('#' + connection.targetId).addClass('row-hover-background');
            }
    
            var removedSelFieldBG = function(connection) {
                $('#' + connection.sourceId).removeClass('row-hover-background');
                $('#' + connection.targetId).removeClass('row-hover-background');
            }
            jsPlumbConnection.connection.bind("mouseover", function(connection) {
                addSelFieldBG(connection);
            });
    
    
            jsPlumbConnection.connection.bind("mouseout", function(connection) {
                removedSelFieldBG(connection);
            });
    
            // For clearing all the mapping
            $('#clear-all').on('click', function() {
              self.jsPlumbInstance.detachEveryConnection();
            });
    
            $('.source,.target').scroll(function() {
              self.jsPlumbInstance.repaintEverything();
            });
        });
        
      // initialize horizontal scroll bar
        var widthDimensions = {
            width:function(ele){
                return ele.width();
            },
            scrollLeft:function(ele){
                return ele[0].scrollLeft;
            },
            clientWidth: function(ele){
                return ele[0].clientWidth;
            }
        };
      initHorizontalScroll();
      
        function initHorizontalScroll() {
            var sourceTableElement = $('.source');
            var widthOfTable = widthDimensions.width(sourceTableElement.find('.source-table'));
            var clientWidth = widthDimensions.clientWidth(sourceTableElement);
    
            var scrollWidth = widthOfTable - clientWidth;
    
            if(isFirefox()) {
                sourceTableElement.scrollLeft(-scrollWidth); // For Firefox offset comes as -scrollWidth to 0
            }
            else if(isIE()) {
                sourceTableElement.scrollLeft(scrollWidth); // For IE offset comes as scrollWidth to 0
            }
            else {
                sourceTableElement.scrollLeft(0); // For Chrome/Safari offset comes as 0 to scrollWidth
            }
        }
    
        function isIE() {
            let userAgent = window.navigator.userAgent.toLowerCase();
            return (userAgent.indexOf('trident') != -1 || userAgent.indexOf('msie') != -1);
        }
    
        function isFirefox() {
            let userAgent = window.navigator.userAgent.toLowerCase();
            return (userAgent.indexOf('firefox') != -1);
        }

        var rightKey = null;
        for (let i = 0; i < self.state.linking.length; i++) {
          if (Object.keys(self.state.linking[i])[0] == self.state.leftKeys[0][0].pnomad_key) { // use js filter method
            rightKey = Object.values(self.state.linking[i])[0];
          }
        }
        const currentColor = self.getIndicatorColor(self.state.leftKeys[0][0].confidence);
        // self.connection = self.jsPlumbInstance.connect({
        //   source: self.state.leftKeys[0][0].pnomad_key,
        //   target: rightKey,
        //   endpoint:"Dot",
        //   endpointStyle:nodeStyle
        // });

        self.connection = self.jsPlumbInstance.connect({
          source: self.state.leftKeys[0][0].pnomad_key,
          target: rightKey,
          paintStyle: {
            strokeWidth: 3,
            stroke: currentColor,
            transition:'.2s'
          }, 
          endpoint:"Dot",
          endpointStyle:{ ...nodeStyle, "outlineStroke": currentColor }
        });

        self.setState({selectedLeftKey:self.state.leftKeys[0][0].pnomad_key})  
      }
    }); // end jsPlumb ready function  
    this.handleDrawBox(this); 
    self.setupTableData(); 
  }

  confirmDelete = () => {
    this.setState({
      isLoading: true
    })
    axios({
        method: 'post',
        url: `${PNOMAD_BASE_URL}/delete_file/`,
        data: {
          "file_id": this.props.match.params.doc_id,
          // "ogi": this.ogi
        },
        headers: {
          'Authorization': `Bearer ${this.state.authenticationToken}`
        }
      })
      .then(res => {
        this.setState({
          isLoading: false,
          dialogOpen: false
        }, () => {
          this.handleGoBack();
        })
      })
      .catch(error => {
        if (error.response) {
          if(error.response.data && error.response.data.non_field_errors){
            this.setState({ isLoading:false,errorMsg: error.response.data.non_field_errors[0]});
          }
        }
      })
    }

    handleShowTable = () => {
      this.setState({
        lineTable: !this.state.lineTable
      })
    }

    restoreDuplicateKey = (currentPage) => {
      var keyName = "";
      for (let i = 0; i < this.state.customName.length; i++) {
        if (Object.keys(this.state.customName[i])[0] == this.state.leftKeys[currentPage][this.state.alteredKey].pnomad_key) { 
            keyName = Object.values(this.state.customName[i])[0];
        }
      }
  
    }

    handleLeftClick = (leftKey, currentPage) => {
      const {pnomad_key,confidence} = leftKey;
      const currentColor = this.getIndicatorColor(confidence);
      if(this.state.alteredKey){
        this.restoreDuplicateKey(currentPage);
      }
      this.globalLeftClickedKey = pnomad_key;
      this.setState({selectedLeftKey:pnomad_key,
                      customInputFocused:null,
                      selectedCustomLeftKey:null})
      var rightKey = "";
  
      for (let i = 0; i < this.state.linking.length; i++) {
        if (Object.keys(this.state.linking[i])[0] == pnomad_key) { // use js filter method
          rightKey = Object.values(this.state.linking[i])[0];
        }
      }
      if(this.jsPlumbInstance){
       
      if (this.connection && this.connection.source && this.connection.target) {
        this.jsPlumbInstance.deleteConnection(this.connection);
      }
  
      if (rightKey) {
  
          this.connection = this.jsPlumbInstance.connect({
            source: pnomad_key,
            target: rightKey,
            paintStyle: {
              strokeWidth: 3,
              stroke: currentColor,
              transition:'.2s'
            }, 
            endpoint:"Dot",
            endpointStyle:{ ...nodeStyle, "outlineStroke": currentColor }
          });
          let elmnt = document.getElementById(rightKey);
          if(elmnt){
            var elementTop = String(elmnt.style.top);
            var percFigure = elementTop.replace("%","");
            var percVal = ((this.bodyHeight * percFigure)/100 ).toFixed(3);
            let calculatedTopOffset = Number(percVal) + 1150*Number(currentPage);
            $("#doc_overflow").scrollTop(calculatedTopOffset);
            
          }
          if(this.state.valueFocussed){
            this.setState({valueFocussed:null})
          }
          this.setState({focusRightKey : rightKey})
      }else{
        this.connection = this.jsPlumbInstance.connect({
          source: pnomad_key,
          target: rightKey,
          endpoint:"Dot",
          endpointStyle:nodeStyle
        });
      }
    }
  }

handleCustomLeftKeyClick = (customLeftKey,currentPage) => {
  this.globalLeftClickedKey = customLeftKey;
  this.setState({selectedCustomLeftKey:customLeftKey,selectedLeftKey:null})
  var rightKey = "";

  for (let i = 0; i < this.state.linking.length; i++) {
    if (Object.keys(this.state.linking[i])[0] == customLeftKey) { // use js filter method
         rightKey = Object.values(this.state.linking[i])[0];
    }
  }

  if (this.connection && this.connection.source && this.connection.target) {
    this.jsPlumbInstance.deleteConnection(this.connection);
  }
  if (rightKey) {
      this.connection = this.jsPlumbInstance.connect({
        source: customLeftKey,
        target: rightKey,
        endpoint:"Dot",
        endpointStyle:nodeStyle
      });
      let elmnt = document.getElementById(rightKey);
      if(elmnt){
        var elementTop = String(elmnt.style.top);
        var percFigure = elementTop.replace("%","");
        var percVal = ((this.bodyHeight * percFigure)/100 ).toFixed(3);
        let calculatedTopOffset = Number(percVal) + 1150*Number(currentPage);
        $("#doc_overflow").scrollTop(calculatedTopOffset);
      }

      this.setState({focusRightKey : rightKey})
  }}

  handleRightClick = (rightKey) => {
    if(this.jsPlumbInstance){
    if (this.connection && this.connection.source && this.connection.target && !this.state.isCustomDeleted) {
      this.jsPlumbInstance.deleteConnection(this.connection);
    }else{
      this.setState({isCustomDeleted:false});
    }
    if (this.state.selectedLeftKey || this.state.selectedCustomLeftKey) {  

      if(this.state.selectedLeftKey){
       
        this.connection = this.jsPlumbInstance.connect({
          source: this.state.selectedLeftKey,
          target: rightKey,
          endpoint:"Dot",
          endpointStyle:nodeStyle
        });
  
        let linkingTemp = this.state.linking;
        var rightKeyIndex = null;
        
        //  -------------------remove previous right key linking-------------------
        for (let i = 0; i < this.state.linking.length; i++) {
          rightKeyIndex = this.state.linking.findIndex(obj => Object.values(obj) == rightKey);
        }

        if (rightKeyIndex >= 0) {
          linkingTemp[rightKeyIndex][Object.keys(linkingTemp[rightKeyIndex])[0]] = "";
          linkingTemp[rightKeyIndex].isEmpty = true;
        }
  
        const leftKeyIdex = linkingTemp.findIndex(obj => Object.keys(obj).includes(this.state.selectedLeftKey))
        //---------------------------update new right key linking------------------------
        if(leftKeyIdex>=0){
          linkingTemp[leftKeyIdex][Object.keys(linkingTemp[leftKeyIdex])[0]] = rightKey;
          
          var emptyKeys = linkingTemp.filter(item => item.isEmpty == true);

          if(emptyKeys.length>=3){
            let emptyKeyNames = emptyKeys.map(item => Object.keys(item)[0]);
            for (let j = 0; j<emptyKeyNames.length-1; j++) {
              var tempss = linkingTemp.findIndex(obj => Object.keys(obj)[0] == emptyKeyNames[j]);
              linkingTemp[tempss][emptyKeyNames[j]] = demoResponse[tempss][emptyKeyNames[j]];
            }
          }
          this.setState({linking: linkingTemp})
        }
      }else if(this.state.selectedCustomLeftKey){

        this.connection = this.jsPlumbInstance.connect({
          source: this.state.selectedCustomLeftKey,
          target: rightKey,
          endpoint:"Dot",
          endpointStyle:nodeStyle
        });
  
        let linkingTemp1 = this.state.linking;
        var rightKeyIndex1 = null;
  
        //  -------------------remove previous right key linking-------------------
        for (let i = 0; i < this.state.linking.length; i++) {
          rightKeyIndex1 = this.state.linking.findIndex(obj => Object.values(obj) == rightKey);
        }

        if (rightKeyIndex1 >= 0) {
          linkingTemp1[rightKeyIndex1][Object.keys(linkingTemp1[rightKeyIndex1])[0]] = "";
        }

        const leftKeyIdexs = linkingTemp1.findIndex(obj => Object.keys(obj).includes(this.state.selectedCustomLeftKey))
        //---------------------------update new right key linking------------------------
        if(leftKeyIdexs>=0){
          linkingTemp1[leftKeyIdexs][Object.keys(linkingTemp1[leftKeyIdexs])[0]] = rightKey;
          this.setState({linking: linkingTemp1})
        }
      }

    } else {
      var leftSourceKey = "";
      for (let i = 0; i < this.state.linking.length; i++) {
        if (Object.values(this.state.linking[i])[0] == rightKey) { // use js filter method
          leftSourceKey = Object.keys(this.state.linking[i])[0];
        }
      }
      this.connection = this.jsPlumbInstance.connect({
        source: leftSourceKey,
        target: rightKey,
        endpoint:"Dot",
        endpointStyle:nodeStyle
      });
    }
  }
}

  getLeftKeyValue = (leftKey) => {
    var rightKey = "";

    for (let i = 0; i < this.state.linking.length; i++) {
      if (Object.keys(this.state.linking[i])[0] == leftKey) { // use js filter method
        rightKey = Object.values(this.state.linking[i])[0];
      }
    }

    for (let j = 0; j < this.state.rightKeys.length; j++) { /// j represents page number
      for (let k = 0; k < this.state.rightKeys[j].length; k++) { // inner object array loop
        if (this.state.rightKeys[j][k].pnomad_key == rightKey) {
          return this.state.rightKeys[j][k].value
        }
      }
    }

    //TODO use the same custom right key's key name as what used in the right key JSON.
    for (let j = 0; j < this.state.customRightKeys.length; j++) { /// j represents page number
      for (let k = 0; k < this.state.customRightKeys[j].length; k++) { // inner object array loop
        if (this.state.customRightKeys[j][k].PnomadRightkey == rightKey) {
          return this.state.customRightKeys[j][k].value
        }
      }
    }
  }

  getCustomLeftKeyValue = (leftKey) =>{

    var rightKey = "";

    for (let i = 0; i < this.state.linking.length; i++) {
      if (Object.keys(this.state.linking[i])[0] == leftKey) { // use js filter method
        rightKey = Object.values(this.state.linking[i])[0];
      }
    }

    for (let j = 0; j < this.state.customRightKeys.length; j++) { /// j represents page number
      for (let k = 0; k < this.state.customRightKeys[j].length; k++) { // inner object array loop
        if (this.state.customRightKeys[j][k].PnomadRightkey == rightKey) {
          return this.state.customRightKeys[j][k].value
        }
      }
    }
  }

  setCustomKeyName = (leftKey) => {
    var keyName = "";
    for (let i = 0; i < this.state.customName.length; i++) {
      if (Object.keys(this.state.customName[i])[0] == leftKey) { // use js filter method
        keyName = Object.values(this.state.customName[i])[0];
          let frags = keyName.split('_');
          for (let i=0; i<frags.length; i++) {
            frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
          }
          var keyStr = frags.join(' '); 
          this.leftKeysArr.push(keyStr);
          this.leftKeysArr = [...new Set(this.leftKeysArr)];    
          return keyStr
      }
    }
  }

  addNewKey = (event, newRightKey=null) => {
    if(event){
      event.preventDefault();
    }
    var listObj = document.getElementById("scrollBeginsHereInv");
      const date = new Date();
      var newDynamicKey = newRightKey||`PnomadNewLeftkey${(date.getTime())}`;

      let newKey = {
        "key": "PnomadNewLeftkey",
        "pnomad_key": newDynamicKey,
        "page_number": 1,
        "table_data": false
      }

    var newLinkingKey = {};
    newLinkingKey[newDynamicKey] =  newDynamicKey+"_2";
  
    var newCustomName = {};
    newCustomName[newDynamicKey] = "New Key";
  
      this.setState({
        showLineItems:false,
        editChangeCount:this.state.editChangeCount+1,
        lineTable:false,
        customLeftKeys: [...this.state.customLeftKeys, newKey],
        linking: [...this.state.linking, newLinkingKey],
        customName: [...this.state.customName, newCustomName],
        leftKeyEditId:newDynamicKey,
        customInputFocused:newDynamicKey          
      },() => {
        listObj.scrollTop = listObj.scrollHeight;
        if(this.jsPlumbInstance){
          this.jsPlumbInstance.addEndpoint(newDynamicKey,{
            endpoint:'Dot',
            anchor:["RightMiddle"]
          })
        }
      }); 
  }

  handleEnterPress = (event,type) => {
    if(event.keyCode === 13){
      if(type=='custom'){
        this.setState({customInputFocused:null,customLeftKeyText:"",inputFocusCustom:false})
      }else{
        this.setState({leftKeyEditId:null,inputFocused:false})
      }
    }
}

handleLeftKeyEdit = (newValue) => {
  var tempCustomName = this.state.customName;
  var popupKeySubStr = this.state.showEditPopupKey.substring(0, this.state.showEditPopupKey.length-1);
  const leftKeyIndex = this.state.customName.findIndex(obj => Object.keys(obj)[0].includes(popupKeySubStr));
  if (leftKeyIndex !== -1) {
    tempCustomName[leftKeyIndex][Object.keys(tempCustomName[leftKeyIndex])[0]] = newValue;
    this.setState({
      leftKeyEditValue: newValue,
      customName: tempCustomName,
      editChangeCount: this.state.editChangeCount + 1,
      showEditPopupKey: null
    })
  }
}

handleCustomLeftKeyEdit = (newValue,leftKey) => {
  var customNameTemp = [...this.state.customName];
  var targetKey = null, targetIndex = null;
  for(let i=0; i<this.state.linking.length ; i++){
    if(Object.values(this.state.linking[i])[0] == leftKey) {
      targetKey = Object.keys(this.state.linking[i])[0];
      targetIndex = i;
    }
  }
  customNameTemp[targetIndex][targetKey] = newValue;
  this.setState({
    customName: customNameTemp,
    showEditPopupKey: null
  })
}

handleCustomLeftAddKeyEdit = (evt, index, leftKey) => {
  var customNameTemp = [...this.state.customName];
  for(let i=0; i<this.state.customName.length ; i++){
    if (Object.keys(this.state.customName[i]) == leftKey) {
       customNameTemp[i][leftKey] = evt.target.value
    }
  }

 var emptyIndex = customNameTemp.findIndex(obj => Object.values(obj).includes(""))
 if(emptyIndex>=0){
  customNameTemp[emptyIndex][Object.keys(customNameTemp[emptyIndex])[0]] = "New Key"
 }

  this.setState({
    customLeftKeyText: evt.target.value,
    customName:customNameTemp,
    editChangeCount:this.state.editChangeCount+1
  })
}

handleCustomLeftAddKeyEdit = (evt, index, leftKey) => {
  var customNameTemp = [...this.state.customName];
  for(let i=0; i<this.state.customName.length ; i++){
    if (Object.keys(this.state.customName[i]) == leftKey) {
       customNameTemp[i][leftKey] = evt.target.value
    }
  }

 var emptyIndex = customNameTemp.findIndex(obj => Object.values(obj).includes(""))
 if(emptyIndex>=0){
  customNameTemp[emptyIndex][Object.keys(customNameTemp[emptyIndex])[0]] = "New Key"
 }

  this.setState({
    customLeftKeyText: evt.target.value,
    customName:customNameTemp,
    editChangeCount:this.state.editChangeCount+1
  })
}

handleAssignLeftClickId = (leftKey) => {
  const leftKeyIndex = this.state.customName.findIndex(obj => Object.keys(obj).includes(leftKey))
  var leftKeyEditValue = this.state.customName[leftKeyIndex][Object.keys(this.state.customName[leftKeyIndex])[0]];
  
  this.setState({
    leftKeyEditId: leftKey,
    leftKeyEditValue
  })
}

handleAssignCustomLeftKey = (customkey) => {
  var defaultVal = "";
  for(let i=0; i<this.state.customName.length ; i++){
    if (Object.keys(this.state.customName[i]) == customkey) {
      defaultVal = Object.values(this.state.customName[i])[0];
    }
  }
  this.setState({customLeftKeyText:defaultVal,
                  inputFocusCustom:true,
                  customInputFocused:customkey})
}

handleFormClick = (event) => {
  event.stopPropagation();
  if (this.state.leftKeyEditId && !this.state.inputFocused) {
    this.setState({
      leftKeyEditId: null
    })
  }
  if (this.state.modelListOpen) {
    this.setState({modelListOpen: false})
  } 
  this.showHideLogoutBtn(null)
}

handleAddNewRightKey = () => {
  const rightKeysCustom = [...this.state.customRightKeys];
  for(let i = 0 ; i < rightKeysCustom.length ; i++){
    if(i === this.props.newRightKey.page_number-1){
      rightKeysCustom[i].push(this.props.newRightKey); 
    }
  }
  this.allowDraw=false;
  this.setState({customRightKeys:rightKeysCustom});
}

componentDidUpdate(prevProps, prevState, snapshot) {
  if(prevProps.customName.length !== this.props.customName.length){
    const leftKeysSorted = [];
    for(let i=0; i< this.props.leftKeys.length; i++){
      leftKeysSorted.push([]);
       const sortedList = this.props.leftKeys[i].sort(function(a, b) {
         return a.confidence - b.confidence;
       });
       leftKeysSorted[i] = sortedList;
     }
    this.setState({
      leftKeys: leftKeysSorted,//this.props.leftKeys,
      rightKeys: this.props.rightKeys,
      customLeftKeys: this.props.customLeftKeys, // addKey not exists in response
      customName: this.props.customName,
      linking: this.props.linking,
      tableSortedData : this.props.tableSortedData,
      customRightKeys:this.props.customRightKeys,
      deletedLeftKeys: this.props.deletedLeftKeys
    }, () => {
      this.jsPlumbReady(this);
    })
  }
  if(prevProps.newRightKey !== this.props.newRightKey){
    this.handleAddNewRightKey()
  }
  if (this.state.customLeftKeys.length > 0) {
    var newDynamicKey = this.state.customLeftKeys[this.state.customLeftKeys.length - 1].pnomad_key;
    let elmnt = document.getElementById(newDynamicKey);
    if (elmnt && this.jsPlumbInstance) {
      this.jsPlumbInstance.addEndpoint(newDynamicKey,{
        endpoint:'Dot',
        anchor:["RightMiddle"]
      })
    }}

    setTimeout(()=>{
      for (let i = 0; i < this.state.customRightKeys.length; i++) {
        for (let j = 0; j < this.state.customRightKeys[i].length; j++) {
          if(this.jsPlumbInstance && !this.state.customRightKeys[i][j].isDeleted){
            this.jsPlumbInstance.addEndpoint(
              this.state.customRightKeys[i][j].PnomadRightkey,{
                endpoint:'Dot',
                anchor:["LeftMiddle"]
              })
            }
          }
        }
      if(this.state.deletedLeftKeys.length>0 && prevState.deletedLeftKeys !== this.state.deletedLeftKeys){
        for (let i = 0; i < this.state.leftKeys.length; i++) {
          for (let j = 0; j < this.state.leftKeys[i].length; j++) {
            if (!this.state.leftKeys[i][j].table_data && !this.isLeftKeyDeleted(this.state.leftKeys[i][j].pnomad_key)) {                  
              if(this.jsPlumbInstance){
                this.jsPlumbInstance.addEndpoint(this.state.leftKeys[i][j].pnomad_key,{
                  endpoint:'Dot',
                  anchor:["RightMiddle"]
                });
              }
            }
          }
        }
      }

    },2000)
}
  handleValueClick = (event,item) => {
    this.setState({valueFocussed:item.pnomad_key})
  }

  handleGoBack = async() => {
    this.props.history.goBack();
  }

  handleDeleteCustomBound = (bound) => {
  
    var customRightKeysTemp = [...this.state.customRightKeys];
    for(let i=0 ; i<customRightKeysTemp.length ; i++){
      for(let j=0; j<customRightKeysTemp[i].length; j++){

        if(customRightKeysTemp[i][j].PnomadRightkey == bound.PnomadRightkey){
          let pageSet  = [...customRightKeysTemp[i]]; 
          pageSet.splice(j,1);
          customRightKeysTemp[i] = pageSet;
        }
      }
    }
    this.setState({customRightKeys : customRightKeysTemp,
                   isCustomDeleted : true }, () => {
      if(this.connection && this.jsPlumbInstance){
        if (this.connection.source && this.connection.source ) {
          this.jsPlumbInstance.deleteConnection(this.connection);
        }
      }
    })
  }

  checkArrayNotEmpty = (data) => {
    Object.size = function(obj) {
      var size = 0,
      key;
      for (key in obj) {
        if (obj[key].length != 0) size++;
      }
      return size;
    };
    
     var size = Object.size(data);
    
     if(size > 0) return true;
     return false;
  }

handleKeyDelete = (leftKey) => {
  var deletedLeftKeys = [...this.state.deletedLeftKeys];
   deletedLeftKeys.push(leftKey);
  this.setState({deletedLeftKeys},()=>{
    this.connection = undefined;
    if(this.jsPlumbInstance){
      this.jsPlumbInstance.remove(leftKey.pnomad_key);
    }
  })
}

isLeftKeyDeleted = (leftKey) => {
  var leftKeyOccurance = 0;
  for (let i=0 ; i<this.state.deletedLeftKeys.length ; i++){
      if(this.state.deletedLeftKeys[i].pnomad_key === leftKey){
        leftKeyOccurance++;
  }}
  if(leftKeyOccurance>0){
    return true
  }
  return false;
}

handleModelDialogClose = () => {
  this.setState({
    newModelDialogOpen:!this.state.newModelDialogOpen
  })
}

showHideLogoutBtn = (flag) => {
  if(flag){      
    this.setState({showLogoutBtn:!this.state.showLogoutBtn})
  }else{
    if(this.state.showLogoutBtn){
      this.setState({showLogoutBtn:false})
    }
  }
   this.setState({modalMenuOpen:false})
}

handleBlurInput = (listObj) => (event) => {
    if(listObj.category=="default"){
      this.setState({leftKeyEditId:null,inputFocused:false})
    }else{
      this.setState({customInputFocused:null,
      customLeftKeyText:"",
      inputFocusCustom:false})
    } 
}

exportFormToExcel = () => {  
  var xlsUrl = `${NGROK_URL}/export_to_excel/?id=${this.props.uploadedFileData.file_id}&response_type=${this.state.exportFormat}`
  window.open(xlsUrl);
}

handleSnackClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  this.setState({successMsg:"",snackOpen:false})
 };

 toggleKeyValueListDisplay = () => {
  this.setState({showInvoiceDetails:!this.state.showInvoiceDetails});
  if(this.connection && this.jsPlumbInstance){
    if (this.connection.source && this.connection.source ) {
      this.jsPlumbInstance.deleteConnection(this.connection);
    }
  }
 }

 showFileUpload = (event) => {
    event.preventDefault();
    this.setState({showUploadModal: !this.state.showUploadModal});
 }

 handleFileUpload = (dataObj) => {
   if(dataObj.type === 'success'){
    this.setState({
      showUploadModal:false,
      successMsg:dataObj.data.message,
      fileId : dataObj.data.file_id,
      snackOpen:true,
      toastColor:'success'},()=>{
        // this.fetchDocument();
      });
   }else{
    this.setState({
      showUploadModal:false,
      successMsg:dataObj.data.message,
      snackOpen:true,
      toastColor:'error'
    });
   }
 }

 handleCloseFileUploadModal = (fileUploaded) => {
   this.setState({showUploadModal:false})
   if(fileUploaded){
     this.props.onFetchDocument(this.props.uploadedFileData);
   }
 }

 handleCloseModal = (modal) => {
   if(modal === 'upload'){
    this.setState({showUploadModal:false})
   }else{
    this.setState({isApproved:false})
   }
 }

  getIndicatorColor = (confidenceLevel) => {
    if (0.5 <= Number(confidenceLevel.toFixed(1)) && Number(confidenceLevel.toFixed(1)) <= 0.7) {
      return 'orange';
    } else {
      if((0.7 <= Number(confidenceLevel.toFixed(1)) && Number(confidenceLevel.toFixed(1)) <= 1)){
        return 'green';
      }else{
        return 'red';
      }
    }
  }

  handleFormatChoiceSelect = (value) => {
    this.setState({
        exportFormat: value,
        isApproved: false
      }, () => 
        this.submitInvoice()
      )
  }

  deleteCustomBound = (item) => (event) => {
    event.stopPropagation();
    if(this.connection.targetId !== item.PnomadRightkey){

    const customRightKeysTemp = [...this.state.customRightKeys];

    for(let i=0; i<customRightKeysTemp.length; i++){
      for(let j=0; j<customRightKeysTemp[i].length; j++){
        if(customRightKeysTemp[i][j].PnomadRightkey === item.PnomadRightkey){
          customRightKeysTemp[i][j].isDeleted = true;
        }
      }
    }
    this.setState({customRightKeys: customRightKeysTemp});
    }
  } 

  showEditKeyDialog = (rightElement) => {
    console.log('bol---',rightElement)
    if(this.connection && this.jsPlumbInstance){
      if (this.connection.source && this.connection.source ) {
        this.jsPlumbInstance.deleteConnection(this.connection);
      }
    }
    const leftKeyIndex = this.state.customName.findIndex(obj => Object.values(obj).includes(rightElement.key))
    var editValue = this.state.customName[leftKeyIndex][Object.keys(this.state.customName[leftKeyIndex])[0]];
    this.setState({
      showEditPopupKey: rightElement.pnomad_key,
      popUpCoords: rightElement.boundingBoxes[0],
      leftKeyEditValue: rightElement.value,
      leftKeyEditKey: editValue
    })
  }

  showEditCustomKeyDialog = (rightElement) => (event) => {
    event.stopPropagation();

    // if(this.connection && this.connection.targetId === rightElement.PnomadRightkey){

    // const leftKeyIndex = this.state.linking.findIndex(obj => Object.values(obj).includes(rightElement.PnomadRightkey))

    // var leftKeyEditValue =  Object.values(this.state.customName[leftKeyIndex]);

    console.log('jjjooo--', this.state.linking);
    
    console.log('tooo--', this.state.customName);

    const leftKeyIndex = this.state.customName.findIndex(obj => {
      console.log('hhh---', Object.keys(obj),rightElement.PnomadRightkey.slice(0,rightElement.PnomadRightkey.length-1))
      return Object.keys(obj)[0].includes(rightElement.PnomadRightkey.slice(0,rightElement.PnomadRightkey.length-1))
    })
    var editLeft =  Object.values(this.state.customName[leftKeyIndex])[0];
    // console.log('bhr---',leftKeyEditValue)

    console.log('jjjjj---', rightElement);

    this.setState({
      showEditPopupKey: rightElement.PnomadRightkey,
      popUpCoords: rightElement.boundingBoxes[0],
      leftKeyEditValue: rightElement.value,//"New Key",
      leftKeyEditKey: editLeft//"New Key"
    })

    // }else{
    //   toast.error('Link a bound before Editing its properties');
    // }
    
  }

  handleDeletePopupKey = () => {
    let customNameTemp=this.state.customName;
    let leftKeysTemp = this.state.leftKeys;
    let rightKeysTemp = this.state.rightKeys;
    let selectedKeySubStr =  this.state.showEditPopupKey.substring(0, this.state.showEditPopupKey.length-1);

    for(let k=0; k<this.state.rightKeys.length; k++){
      for( let l = 0 ; l < this.state.rightKeys[k].length ; l++ ){
        if(String( this.state.rightKeys[k][l].pnomad_key ).includes(selectedKeySubStr)){
          rightKeysTemp[k].splice(l,1);
        }
      }
    }

    for( let m = 0; m < this.state.customName.length; m++ ){
      if(Object.keys(this.state.customName[m])[0].includes(selectedKeySubStr)){
        customNameTemp.splice(m,1);
      }
    }

    this.setState({
      showEditPopupKey: null,
      rightKeys: rightKeysTemp,
      customName: customNameTemp
    });
  }

  render(){
    const { fileImages, fileName } = this.props; 
    return (
        <div className="px-3" style={{position:'relative'}}>
        <SnackbarComponent 
          isOpen={this.state.snackOpen} 
          message={this.state.successMsg} 
          color={this.state.toastColor}
          handleClose={this.handleSnackClose}
        />
        <FileUploadModal open={this.state.showUploadModal} 
          docType="INVOICE"
          onClose={this.handleCloseFileUploadModal} 
        />

          <SelectExportDialog 
            open={this.state.isApproved} 
            fetchFormatChoice={this.handleFormatChoiceSelect}
            onClose={this.handleCloseModal} 
          />

          <div onClick={this.handleFormClick}>
          <div className="row" id="detailHeader" style={{zIndex:100}} > 
          <div className="col-9 d-flex align-items-center" style={{zIndex:1, backgroundColor: '#2F455C'}}>
          <nav id="myBar" className="navbar detail-header-form px-4" style={{zIndex:1,maxHeight:'98px',alignItems:'flex-start'}}>
            <h1>Nomad</h1>             
          </nav>
          </div>
          <div className="col-3 pt-3" style={{zIndex:1, backgroundColor: '#2F455C'}}>
            <div className="form-group formbanner-head">
              <a type="button" className="btn">Home</a>
              <a type="button" className="btn" onClick={this.showFileUpload}>Upload</a>
              <img src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50" height="50" width="50"
               alt="user_avatar" className="img-thumbnail"/>
            </div>
          </div>  
      </div> 
      <div className="row justify-content-between" 
      id="drag_canvas" 
      onClick={()=>this.showHideLogoutBtn(null)}>
        {
          this.state.invalidInvoice &&
          <div className="modal fade show" 
              id="exampleModal" 
              style={{display:'block',background:'rgba(0,0,0,0.8)'}}
              tabindex="-1" 
              onClick={()=>this.setState({invalidInvoice:false})}
              role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog" role="document"  onClick={(event)=>event.stopPropagation()}>
                <div className="modal-content" style={{margin:'40% 0'}}>
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel"></h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.setState({invalidInvoice:false})}>
                      <span style={{fontSize:'24px',color:'#fff'}} aria-hidden="true">&times;</span>
                          </button>
                      </div>
                      <h5 className="text-center" style={{color:'#fff'}}>Invalid or Corrupt Invoice</h5>
                      <div className="modal-footer" style={{justifyContent:'center'}}>
                        <button type="button" className="btn" style={{backgroundColor:'#011B3E'}} onClick={()=>this.handleGoBack()} 
                        data-dismiss="modal">Go Back</button>
                        <button type="button" className="btn" style={{border:'1px solid aqua',color:'aqua',backgroundColor:'transparent'}}
                        onClick={()=>this.setState({invalidInvoice:false})} 
                        data-dismiss="modal">Continue</button>
                      </div>
                  </div>
              </div>
          </div>
        }

        <Spinner open={this.props.isLoading} />
          <div className="col-sm-8 target pull-right" id="doc_overflow"
          style={{marginTop:'unset',height:'89vh',position:'relative'}}>
            {/* {
              this.state.showEditPopup &&
              <EditCustomKeyDialog 
                defaultValue="ssss" 
                open={this.state.showEditPopup} 
                coords={this.state.popUpCoords}
              />
            } */}
            {
              fileImages.map((item,index) => {
                return (
                  <img className="img-shadow" 
                    src = {item}
                    style = {{
                    filter: 'brightness(1) contrast(1)', 
                    display: 'initial', 
                    transform: `scale(${this.state.zoomFactor})`,
                    backgroundColor: 'white',  
                    left: '45px',
                    position:'absolute',  
                    top: 1150*index, 
                    width:'870px',
                    height: '1150px'}} />
                  )
                })
            }
            {
          this.state.rightKeys && this.state.rightKeys.length>0 && 
          this.state.rightKeys.slice(0, 3).map((pageArr,mainIndex) => {
              return (
              <div id="invoiceImg" title={mainIndex} className="formwrapper" style={{top:1150*mainIndex,left:'45px',
              transform: `scale(${this.state.zoomFactor})`}}>
                {
                  pageArr.map((item,index) => {
                    return(
                      <div 
                        id={item.pnomad_key} 
                        onClick={()=>this.showEditKeyDialog(item)}
                        // onClick={()=>this.handleRightClick(item.pnomad_key)}
                        style={{
                          border: `2px solid ${this.getIndicatorColor(item.confidence)}`,
                          background:'rgba(26, 115, 232,0.2)',
                          position:'absolute',
                          cursor:'pointer',
                          top: item.boundingBoxes[0].y1*100+'%',
                          left: item.boundingBoxes[0].x1*100+'%',
                          width: (item.boundingBoxes[0].x2*100 - item.boundingBoxes[0].x1*100)+'%',
                          height: (item.boundingBoxes[0].y4*100 - item.boundingBoxes[0].y1*100)+'%'
                        }}>
                          {
                            this.state.showEditPopupKey === item.pnomad_key &&
                            <EditCustomKeyDialog 
                              defaultValue={this.state.leftKeyEditValue}
                              defaultKey={this.state.leftKeyEditKey}
                              onDelete={this.handleDeletePopupKey}
                              onSave={this.handleLeftKeyEdit}
                            />
                          }
                      </div> 
                    );
                  })
                }
                {
                  this.checkArrayNotEmpty(this.state.customRightKeys) && 
                  this.state.customRightKeys[mainIndex].map((item,index) => {
                      return(
                        <>
                          {
                            !item.isDeleted &&
                            <div id={item.PnomadRightkey} 
                              onClick = {()=>this.handleRightClick(item.PnomadRightkey)}
                              style={{
                                  background:'rgba(26, 115, 232,0.2)',
                                  position:'absolute',
                                  cursor:'pointer',
                                  top: item.boundingBoxes[0].y1*100+'%',
                                  left: item.boundingBoxes[0].x1*100+'%',
                                  width: (item.boundingBoxes[0].x2*100 - item.boundingBoxes[0].x1*100)+'%',
                                  height: (item.boundingBoxes[0].y4*100 - item.boundingBoxes[0].y1*100)+'%'
                                }}>
                                  <img 
                                    onClick={this.deleteCustomBound(item)} 
                                    src={Images.delete_key}
                                    style={{
                                      height: 15,
                                      padding: 0,
                                      left: '100%',
                                      position: 'absolute'
                                    }}
                                    alt="delete_key" className="img-thumbnail"
                                  >
                                  </img>
                                  <img 
                                    // onClick={this.deleteCustomBound(item)} 
                                    onClick={this.showEditCustomKeyDialog(item)}
                                    src={Images.editIcon}
                                    style={{
                                      height: 15,
                                      padding: 0,
                                      top: 10,
                                      left: '100%',
                                      position: 'absolute'
                                    }}
                                    alt="delete_key" className="img-thumbnail"
                                  >
                                  </img>
                                  {
                                    this.state.showEditPopupKey === item.PnomadRightkey &&
                                    <EditCustomKeyDialog 
                                      defaultValue={this.state.leftKeyEditValue}
                                      defaultKey={this.state.leftKeyEditKey}
                                      onDelete={this.handleDeletePopupKey}
                                      onSave={(value)=>this.handleCustomLeftKeyEdit(value,item.PnomadRightkey)}
                                    />
                                  }
                            </div>
                          }
                        </>
                      );
                  })
                }

                {
                  this.state.tableSortedData.length>0 && this.state.tableSortedData[mainIndex]? 
                  this.state.tableSortedData[mainIndex].map(page => {
                    return (
                        page.map(item => {
                          return (
                          <div id={item.table_data_id} 
                            style={{
                                background:'rgba(26, 115, 232,0.2)',
                                position:'absolute',
                                cursor:'pointer',
                                top: item.bounding_box[0].y1*100+'%',
                                left: item.bounding_box[0].x1*100+'%',
                                width: (item.bounding_box[0].x2*100 - item.bounding_box[0].x1*100)+'%',
                                height: (item.bounding_box[0].y4*100 - item.bounding_box[0].y1*100)+'%'
                              }}>
                          </div>
                          )
                        })
                    )
                  }):
                  null
                }
              </div>  

              )
            })
          }
      </div>
          <div className="col-sm-4 py-3 pr-0 formlist-container">
            <div className="board-wrapperinv" 
            style={{position:'relative'}}>
            <div style={{flex:1}}>
            <div className="col-12 px-3 source" id="scrollBeginsHereInv">
              {
                fileName &&
                <div className="form-group file-name d-flex">
                    <label for="staticFileName" className="col-sm-3 col-form-label px-0">File Name:</label>
                    <div className="col-sm-9 pl-0">
                      <input type="text" readonly className="form-control-plaintext" id="staticFileName" value={ fileName } />
                    </div>
                </div> 
              }
             <div className= {this.state.leftKeys.length>0?"source-table":"source-table no-data"}>
               <ul className="source-list">
                {
                  this.state.leftKeys.length>0 ? this.state.leftKeys.map((page,pageIndex) => (
                    page.map((item,index) => {
                    if(!item.table_data==true){ 
                      return(
                        <li id = {item.pnomad_key}
                        style={{
                          height:'32px',
                          direction:'ltr',
                          cursor: 'default'
                        }}
                        onClick={()=>this.handleLeftClick(item,pageIndex)}
                        className="d-flex justify-content-start mt-3 align-items-center">
                          {
                              !this.isLeftKeyDeleted(item.pnomad_key) &&
                              <>
                              <div className="key-leaf" style={{
                                backgroundColor:this.getIndicatorColor(item.confidence),
                              }}></div>

                                <BootstrapTooltip title={this.setCustomKeyName(item.pnomad_key,"natural")}>
                                  <span className="left-key-span"
                                    onClick={()=>this.handleAssignLeftClickId(item.pnomad_key)}>
                                    { this.setCustomKeyName(item.pnomad_key,"natural") }
                                  </span>
                                </BootstrapTooltip>

                              {
                                this.state.valueFocussed == item.pnomad_key ?
        
                                <input id="value-read"type="text" name="lname" readOnly
                                onBlur={()=>this.setState({valueFocussed:null})}
                                value={this.getLeftKeyValue(item.pnomad_key)}/>
                                :
                                <BootstrapTooltip title={this.getLeftKeyValue(item.pnomad_key)}>
                                <span className="left-key-value ml-1"
                                onClick={(event)=>this.handleValueClick(event,item)}>{this.getLeftKeyValue(item.pnomad_key)}</span>
                                </BootstrapTooltip>
                              }
                              {
                                (0.5 <= Number(item.confidence.toFixed(1)) && Number(item.confidence.toFixed(1)) <= 0.7)?
                              <div className="dot-list">
                                <a style={{color:'#242423'}}>•</a>
                                <a style={{color:'orange'}}>•</a>
                                <a style={{color:'orange'}}>•</a>
                              </div>
                              :(0.7 <= Number(item.confidence.toFixed(1)) && Number(item.confidence.toFixed(1)) <= 1)?
                              <div className="dot-list">
                                <a style={{color:'green'}}>•</a>
                                <a style={{color:'green'}}>•</a>
                                <a style={{color:'green'}}>•</a>
                              </div>
                              :
                              <div className="dot-list">
                                <a style={{color:'#242423'}}>•</a>
                                <a style={{color:'#242423'}}>•</a>
                                <a style={{color:'red'}}>•</a>
                              </div>
                              }
                              <div className="p-1" style={{height:25}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                                </svg>
                              </div>    
                          </>
                      }
                        </li>
                      );
                    }
                    })
                  )):
                  <div className="d-flex justify-content-center align-items-center" style={{height:'100%'}}>
                    <p className="m-0">Nothing to show<br/>Please try uploading a file</p>
                  </div>
                }
                {
                  this.state.customLeftKeys && this.state.customLeftKeys.length>0 && this.state.customLeftKeys.map((newKeys,index) => {
                    return (
                    <li id={newKeys.pnomad_key}
                      style={{
                        height:'42px',
                        direction:'ltr',
                        padding:'10px 10px 10px 15px'
                      }}
                    onClick={()=>this.handleCustomLeftKeyClick(newKeys.pnomad_key,index)}
                    className={this.state.selectedCustomLeftKey==newKeys.pnomad_key?
                      "d-flex justify-content-between align-items-center pair-selected":
                      "d-flex justify-content-between align-items-center"}>   
                  {
                  !this.isLeftKeyDeleted(newKeys.pnomad_key) &&
                  <>
                  {
                    // this.state.inputFocusCustom && this.state.customInputFocused == newKeys.pnomad_key ? 
                    
                    //       <input type="text" 
                    //       className="details_input mr-1"
                    //       id={newKeys.pnomad_key} 
                    //       name = {newKeys.pnomad_key} 
                    //       value={this.state.customLeftKeyText}
                    //       style={{color:'#fff'}} 
                    //       onFocus={()=>this.setState({inputFocusCustom:true})}
                    //       onKeyUp={(evt)=>this.handleEnterPress(evt,'custom')}
                    //       onChange={(evt) => this.handleCustomLeftAddKeyEdit(evt,index,newKeys.pnomad_key)}
                    //       onBlur={this.handleBlurInput({index,category:"custom"})}/>
                    //         :
                          <BootstrapTooltip title= {this.setCustomKeyName(newKeys.pnomad_key,"custom")}>
                          <span
                          className="left-key-span"
                          // onClick={()=>this.handleAssignCustomLeftKey(newKeys.pnomad_key)}
                          >
                            {this.setCustomKeyName(newKeys.pnomad_key,"custom")}
                          </span>
                        </BootstrapTooltip>
                  }
                      <BootstrapTooltip title={this.getLeftKeyValue(newKeys.pnomad_key)}>
                        <span 
                          className="left-key-value ml-1"
                          >{this.getLeftKeyValue(newKeys.pnomad_key)}</span>
                      </BootstrapTooltip>
                      {
                        this.state.selectedCustomLeftKey == newKeys.pnomad_key &&
                        <img onClick={()=>this.handleKeyDelete(newKeys)} src={Images.delete_key}
                        alt="delete_key" className="img-thumbnail"></img>
                      }
                      </>
                      }
                    </li>
                    ) 
                  })
                }                                            
                </ul>
              </div>
              {
                this.state.leftKeys.length>0 &&
                <>
                <div className="form-group file-name d-flex mt-5" style={{borderLeft: '2px solid aqua'}}>
                      <label className="col-sm-7 col-form-label pr-0">Line Items:</label>
                      <div className="col-sm-5 pl-0">
                        <button type="button" id="lineItemLabel" className="btn btn-outline-primary w-100"
                        onClick={()=>this.handleShowTable()}>View</button>
                  </div>
                </div>
                </>
              }
                 {
                    this.state.leftKeys.length>0 &&
                    <div className="btn_group cnf_btn_form d-flex justify-content-between mt-5">
                      <button onClick={(event) => this.addNewKey(event, null)} type="button" className="btn btn-outline-danger">Add Fields</button>
                      <button
                        type="button"
                        className="btn btn-outline-success" 
                        onClick={() => this.setState({isApproved:true})}>
                          Approve
                        </button>
                    </div>
                  }
            </div>
            </div>
            </div>
            </div>
        </div>
      {
      this.state.lineItemTableData.length>0 && this.state.lineTable?
        <div className="invoice-table">
        <div className="table-container">
          <div className="col-12 close_btn_inv mt-2 pr-1">
            <i className="fa fa-times" onClick={()=>{
            this.setState({
              lineTable: false
            })
          }}></i></div>
          {
          this.state.tableSortedData.length>0 && this.state.tableSortedData.map(page => {
            return (
              <div className="form-group px-3">
                {
                  page.map(table => {
                    return (
                     <div style={{border: '2px solid #0175ff',borderRadius: 12, marginTop: 10}}> 
                      <table className="table line-table borderless">
                        <thead>
                          <tr>
                            {
                              table[0].key.map(heading => <th scope="col" style={{border:'none'}}>{heading}</th>)
                            }
                            <th scope="col" style={{border:'none'}}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            table.map(item => {
                              return (
                                <tr onClick={() => this.handleFocusRow(item)}>
                                  {
                                    item.value.map(cell => {
                                    return <td style={{border:'none'}}>{cell}</td>
                                    })
                                  }
                                </tr>
                              )
                            })
                          }
                        </tbody>
                    </table>
                    </div>
                    )
                  })
                }
              </div> 
            )
          })
         }
        </div> 
      </div>:null
      } 
  </div>  
    </div>
    );
  }

}

const mapStateToProps = state => ({
  storiesLA: state,
  uploadedFileData: state.docDetailsState.fileUploadData,
  isLoading: state.docDetailsState.isLoading,
  fileImages: state.docDetailsState.fileImages,
  fileName: state.docDetailsState.fileName,
  leftKeys: state.docDetailsState.leftKeys,
  rightKeys: state.docDetailsState.rightKeys,
  customLeftKeys: state.docDetailsState.customLeftKeys,
  customName: state.docDetailsState.customName,
  linking: state.docDetailsState.linking,
  tableSortedData : state.docDetailsState.tableSortedData,
  customRightKeys: state.docDetailsState.customRightKeys,
  deletedLeftKeys: state.docDetailsState.deletedLeftKeys,
  newRightKey: state.docDetailsState.newRightKey
});
 
const mapDispatchToProps = dispatch => ({
  onArchive: id => dispatch(doArchiveStory(id)),
  onFetchDocument: fileData => dispatch(doFetchDocument(fileData)),
  onFetchStories: query => dispatch(doFetchStories(query)),
  onExtractDataCoordinate: payload => dispatch(doFetchDataCoordinates(payload)),
  onInvoiceSubmit: payload => dispatch(doSubmitInvoice(payload))
});

export default connect(mapStateToProps,mapDispatchToProps)(App);