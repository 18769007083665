const doFileUpload = filesData => ({
    type: 'UPLOAD_FILE',
    filesData
  });

  const doIsLoading = () => ({
    type: 'SET_LOADING',
  });

  const doFileUploadSuccess = (data) => ({
    type: 'UPLOAD_FILE_SUCCESS',
    data
  });

  const doFileUploadError = (error) => ({
    type: 'UPLOAD_FILE_ERROR',
    error
  });

  
  const doFetchDocument = (fileData) => ({
    type: 'FETCH_DOCUMENT',
    fileData
  });
  const doFetchDocumentSuccess = (data) => ({
    type: 'FETCH_DOCUMENT_SUCCESS',
    data
  });
  const doFetchDocumentError = (error) => ({
    type: 'FETCH_DOCUMENT_ERROR',
    error
  });

  const doExtractData = (fileData) => ({
    type: 'EXTRACT_DATA',
    fileData
  });
  const doExtractDataSuccess = (data) => ({
    type: 'EXTRACT_DATA_SUCCESS',
    data
  });
  const doExtractDataError = (error) => ({
    type: 'EXTRACT_DATA_ERROR',
    error
  });

  
  const doFetchDataCoordinates = (payload) => ({
    type: 'EXTRACT_DATA_COORDS',
    payload
  });
  const doFetchDataCoordinatesSuccess = (data) => ({
    type: 'EXTRACT_DATA_COORDS_SUCCESS',
    data
  });
  const doFetchDataCoordinatesError = (error) => ({
    type: 'EXTRACT_DATA_COORDS_ERROR',
    error
  });
  
  const doSubmitInvoice = (payload) => ({
    type: 'SUBMIT_INVOICE',
    payload
  });
  const doSubmitInvoiceSuccess = (data) => ({
    type: 'SUBMIT_INVOICE_SUCCESS',
    data
  });
  const doSubmitInvoiceError = (error) => ({
    type: 'SUBMIT_INVOICE_ERROR',
    error
  }); 

  const doDownloadFile = (payload) => ({
    type: 'DOWNLOAD_FILE',
    payload
  });
  const doDownloadFileSuccess = (data) => ({
    type: 'DOWNLOAD_FILE_SUCCESS',
    data
  });
  const doDownloadFileError = (error) => ({
    type: 'DOWNLOAD_FILE_ERROR',
    error
  }); 
  // const doAddStories = stories => ({
  //     type: 'ADD_STORIES',
  //     stories,
  //   });
    
  //   const doFetchStories = query => ({
  //     type: 'FETCH_STORIES',
  //     query,
  //   });
    
  //   const doFetchStoriesError = error => ({
  //     type: 'STORIES_FETCH_ERROR',
  //     error
  //   });
    
    export {
      // doAddStories,
      // doFetchStories,
      // doFetchStoriesError
      doFileUpload,
      doFileUploadSuccess,
      doFileUploadError,
      doIsLoading,
      doFetchDocument,
      doFetchDocumentSuccess,
      doFetchDocumentError,
      doExtractData,
      doExtractDataSuccess,
      doExtractDataError,
      doFetchDataCoordinates,
      doFetchDataCoordinatesSuccess,
      doFetchDataCoordinatesError,
      doSubmitInvoice,
      doSubmitInvoiceError,
      doSubmitInvoiceSuccess,
      doDownloadFile,
      doDownloadFileSuccess,
      doDownloadFileError
    };