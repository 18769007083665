import React from "react";
import "components/SelectExportDialog/selectExportFormat.module.scss";
import Images from "assets/asset_imports";
import {Backdrop,Modal, makeStyles} from "@material-ui/core";
import { memo } from 'react';

const SelectExportPopup = (props) => {
    const { open, fetchFormatChoice, onClose } = props;
    const accordionStyles = makeStyles((theme) => ({
        backdrop: {
          zIndex: 10,
          color: "#fff",
        }
    }));
    const classes = accordionStyles();  

  return (
    <Backdrop
      className={classes.backdrop}
      open={open}>
        <Modal
          open={open}
          style={{ display:'flex',justifyContent:'center',alignItems:'center'}}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={{ backgroundColor: '#FFFFFF',width:520,height:246,borderRadius:6 }} align="center">
          <div className="modal-header">
        <button 
          type="button" 
          className="close" 
          data-dismiss="modal" 
          aria-label="Close"
          onClick={()=>onClose('download')}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
            <div className="modal-content">
              <img src={Images.file_download} style={{width:35,height:33,marginTop:12}}/>
              <div style={{ color: '#1C5784',font:'normal normal bold 15px/26px Open Sans',opacity:1,letterSpacing:0, marginTop:10 }}>
                Select a format to Export the data
              </div>

              </div>
              <div className="modal-footer justify-content-center">
                <button type="button"
                  onClick={()=>fetchFormatChoice("excel")} 
                  className="btn btn-outline-success"> 
                    <i className="fa fa-file"></i>  Export to Excel
                  </button>
                <button 
                  onClick={()=>fetchFormatChoice("csv")}
                  type="button" 
                  className="btn btn-outline-primary"
                >
                  <i className="fa fa-file"></i>  Export to CSV
                </button>
                <button 
                  onClick={()=>fetchFormatChoice("json")}
                  type="button" 
                  className="btn btn-outline-danger"
                >
                  <i className="fa fa-file"></i>  Export to JSON
                </button>
              </div>
          </div>
      </Modal>
    </Backdrop>
  );
}

export default memo(SelectExportPopup);
