import React, { memo } from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";

const Spinner = ({ open }) => (
    <Backdrop style={{zIndex:10,color: '#fff'}} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );

export default memo(Spinner);
