import React, {useEffect} from 'react';
import Routes from './views/App';
import { BrowserRouter as Router } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

const App = () => (
  <>
    <Toaster position="top-center" />
    <Router>
      <Routes />
    </Router>
  </>
 );

export default App;
