import { takeEvery, all, takeLatest } from 'redux-saga/effects';
import {
  handleFetchDocument,
  handleFileUpload,
  handleExtractData,
  handleExtractDataCoordinates,
  handleSubmitInvoice,
  handleFileDownload
} from 'views/DocDetails/docDetailsSaga';
import { handleFetchStories } from './storySaga';
 
function* watchAll() {
  yield all([
    takeEvery('FETCH_STORIES', handleFetchStories),
    takeEvery('UPLOAD_FILE', handleFileUpload),
    takeLatest('FETCH_DOCUMENT', handleFetchDocument),
    takeLatest('EXTRACT_DATA', handleExtractData),
    takeLatest('EXTRACT_DATA_COORDS', handleExtractDataCoordinates),
    takeLatest('SUBMIT_INVOICE', handleSubmitInvoice),
    takeLatest('DOWNLOAD_FILE', handleFileDownload)

  ])
}
 
export default watchAll;