import {combineReducers} from 'redux';
import storiesReducer from 'reducers/storiesReducer';
import archiveReducer from 'reducers/archiveReducer';
import progressReducer from 'reducers/progressReducer';
import docDetailsReducer from './docDetailsReducer';


const rootReducer = combineReducers({
  storyState: storiesReducer,
  archiveState: archiveReducer,
  docDetailsState: docDetailsReducer
});

export default rootReducer;