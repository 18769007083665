const doAddStories = stories => ({
  type: 'ADD_STORIES',
  stories,
});

const doFetchStories = query => ({
  type: 'FETCH_STORIES',
  query,
});

const doFetchStoriesError = error => ({
  type: 'STORIES_FETCH_ERROR',
  error
});

export {
  doAddStories,
  doFetchStories,
  doFetchStoriesError
};