const INITIAL_STATE = {
    isLoading: false,
    isUploadLoading: false,
    fileImages: [],
    fileName:"",
    leftKeys: [],
    rightKeys: [],
    customLeftKeys: [],
    customName: [],
    linking: [],
    tableSortedData : [],
    customRightKeys: [],
    deletedLeftKeys: [],
    newRightKey: null
};

function docDetailsReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
      case 'UPLOAD_FILE': {
        return {...state, "isUploadLoading":true};
      }
      case 'UPLOAD_FILE_SUCCESS': {
        const {data} = action;
        return {...state, "fileUploadData":data, "isUploadLoading":false };
      }
      case 'UPLOAD_FILE_ERROR': {
        const {error} = action;
        return {...state, "fileUploadError":error.statusText, "isUploadLoading":false };
      }
      case 'FETCH_DOCUMENT': {
        return {...state, "isLoading":true};
      }
      case 'FETCH_DOCUMENT_SUCCESS': {
        const {data} = action;
        return {...state, "fileImages":data.file_link.split(","), "fileName": data.original_file_name||data.vendor_name, "isLoading":false };
      }
      case 'FETCH_DOCUMENT_ERROR': {
        const {error} = action;
        return {...state, "fetchDocumentError":error.statusText, "isLoading":false };
      }
      case 'EXTRACT_DATA': {
        return {...state, "isLoading":true};
      }
      case 'EXTRACT_DATA_SUCCESS': {
        const {data} = action;
        // handleToastMessage(data);
        const temp = [];
          for(let i = 0 ; i < data.data.right.length ; i++ ){
            temp.push([])
          }
        return {...state,
                "isLoading":false,
                leftKeys: data.data.left,
                rightKeys: data.data.right,
                customLeftKeys: data.data.customLeftKeys || [],
                customName: data.data.custom_name,
                linking: data.data.linking,
                tableSortedData : data.data.table_data,
                customRightKeys: data.data.customRightKeys && data.data.customRightKeys.length > 0 ? data.data.customRightKeys : temp,
                deletedLeftKeys: data.data.deletedLeftKeys && data.data.deletedLeftKeys.length > 0 ? data.data.deletedLeftKeys : []
             };
      }
      case 'EXTRACT_DATA_ERROR': {
        const {error} = action;
        return {...state, "extractDataError": error ? error.statusText: "", "isLoading":false };
      }
       case 'EXTRACT_DATA_COORDS': {
        return {...state, "isLoading":true};
      }
      case 'EXTRACT_DATA_COORDS_SUCCESS': {
          
          const date = new Date();
          const {data: {result, rightKey}} = action;
          const rightKeyTemp = {...rightKey};
          rightKeyTemp.value = result.value;
          rightKeyTemp.key = `CustomBoundBox${date.getTime()}`;     
          return {...state, "newRightKey": rightKeyTemp, "isLoading": false}
      }
      case 'EXTRACT_DATA_COORDS_ERROR': {
        const {error} = action;
        return {...state, "fetchDataCoordError":error.statusText, "isLoading":false };
      }
      case 'SUBMIT_INVOICE': {
        return {...state, "isLoading":true};
      }
      case 'SUBMIT_INVOICE_SUCCESS': {
        const {data} = action;
        return {...state, "invoiceSubmit":data, "isLoading":false };
      }
      case 'SUBMIT_INVOICE_ERROR': {
        const {error} = action;
        return {...state, "invoiceSubmitError":error.statusText, "isLoading":false };
      }

      case 'DOWNLOAD_FILE': {
        return {...state, "isLoading": true};
      }
      case 'DOWNLOAD_FILE_SUCCESS': {
        const {data} = action;
        return {...state, "isLoading": false};
      }
      case 'DOWNLOAD_FILE_ERROR': {
        const {error} = action;
        return {...state, "isLoading": false};
      }
      
      default : return state;
    }
  }
export default docDetailsReducer;
