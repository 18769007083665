import axios from 'axios';
import { apiHeaders, NGROK_URL } from 'utils/constants';


const axiosClient = axios.create();

axiosClient.defaults.baseURL = NGROK_URL;

// Important: If axios is used with multiple domains, the AUTH_TOKEN will be sent to all of them.
// See below for an example using Custom instance defaults instead.
// axiosClient.defaults.headers.common['Authorization'] = AUTH_TOKEN;

axiosClient.defaults.headers = apiHeaders;
// axiosClient.defaults.withCredentials = true;

export function getRequest(URL) {
  return axiosClient.get(`/${URL}`).then((response) => response);
}

export function postRequest(URL,payload) {
  return axiosClient.post(`/${URL}`,payload).then((response) => response);
}