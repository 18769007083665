import React, { useEffect, useState } from "react";
import "components/EditCustomKeyDialog/editCustomKey.module.scss";

const EditCustomKeyDialog = (props) => {
  const { onDelete, onSave, defaultValue, defaultKey } = props;
  const [newValue, setNewValue] = useState(defaultKey);

  const handleDelete = (event) => {
    event.stopPropagation();
    onDelete();
  }

  const handleSave = (event) => {
    event.stopPropagation();
    onSave(newValue);
  }

  const handleInputChange = (event) => {
    if (event.which == '13') {
      event.preventDefault();
    }
    setNewValue(event.target.value);
  }

  return (
    <div className='editmodal-wrapper'
     style={{
       marginLeft: '100%',
       marginTop: '-50%'
     }}
    >
      <div className="input-wrapper">
        <input 
          className="form-control mt-3"  
          // value={defaultValue}
          value={newValue}
          onChange={handleInputChange}
          placeholder="enter new value"
        />
      </div>
      <div className="input-wrapper mt-3">
        <input 
          className="form-control"  
          // value={newValue}
          value={defaultValue}
          // placeholder="enter new value"
          // onChange={handleInputChange}
          readOnly
        />
      </div>
          <div className="form-inline justify-content-around mt-4">
            <button 
              onClick={handleDelete}
              type="button"
              className="btn btn-danger"
            >
              Delete
            </button>
            <button 
              onClick={handleSave}
              type="button" 
              className="btn btn-success"
            >
              Save
            </button>
          </div>
    </div>
  );
}

export default EditCustomKeyDialog;