import { call, put } from 'redux-saga/effects';
import { doFileUploadSuccess, doFileUploadError,
  doFetchDocumentSuccess, 
  doFetchDocumentError,
  doExtractData,
  doExtractDataError,
  doExtractDataSuccess,
  doFetchDataCoordinatesSuccess,
  doFetchDataCoordinatesError,
  doSubmitInvoiceSuccess,
  doSubmitInvoiceError,
  doDownloadFile,
  doDownloadFileSuccess,
  doDownloadFileError 
} from 'views/DocDetails/docDetailsActions';
import { postRequest } from 'utils/apiHandlers/axiosClient';
import { getRequestFile } from 'utils/apiHandlers/axiosFileClient';
import { postFormRequest } from 'utils/apiHandlers/axiosFormDataClient';
import { handleToastMessage } from 'utils/apiHandlers/toastHelper';
const fileDownload = require('js-file-download');

function* handleFileUpload(action) {
  try{
    const response = yield call(() => postFormRequest('file_upload', action.filesData));
    handleToastMessage(response.data);
    yield put(doFileUploadSuccess(response.data));
  }catch(error){
    handleToastMessage(error);
    yield put(doFileUploadError(error.response));
  }
}

function* handleFetchDocument(action) {
  const reqBody = { file_id: action.fileData.file_id }
  try{
    const response = yield call(() => postRequest('open_doc', reqBody));
    yield put(doFetchDocumentSuccess(response.data));
    yield put(doExtractData(action.fileData.file_id));
  }catch(error){
    yield put(doFetchDocumentError(error.response));
  }
}

function* handleExtractData(action) {
  const reqBody = { file_id: action.fileData }
  try{ 
    const response = yield call(() => postRequest('extract_data', reqBody));
    yield put(doExtractDataSuccess(response.data));
    
  }catch(error){
    yield put(doExtractDataError(error.response));
  }
}

function* handleExtractDataCoordinates(action) {
  const reqBody = {...action.payload.rightKey, "file_id": action.payload.file_id }
  try{ 
    const response = yield call(() => postRequest('extract_coordinate_data', reqBody));
    handleToastMessage(response.data);
    yield put(doFetchDataCoordinatesSuccess({result: response.data, rightKey : action.payload.rightKey}));
  }catch(error){
    handleToastMessage(error);
    yield put(doFetchDataCoordinatesError(error.response));
  }
}

function* handleFileDownload(action) {
  const { payload: { format, id, fileName} } = action;
  try{ 
    const exportFileName = format === 'excel' ? `${fileName}.xls` : format === 'json'? `${fileName}.json` : `${fileName}.csv`;
    const response = yield call(() => getRequestFile(`export_to_excel/?id=${id}&response_type=${format}`));
    fileDownload(response.data, exportFileName);
    yield put(doDownloadFileSuccess({result: response.data, rightKey : action.payload.rightKey}));
  }catch(error){
    handleToastMessage(error);
    yield put(doDownloadFileError(error.response));
  }
}

function* handleSubmitInvoice(action) {
  try{
    const response = yield call(() => postRequest('update_keys', action.payload.reqBody));
    const fileObj = {
      format: action.payload.format,
      id:  action.payload.reqBody.file_id,
      fileName: action.payload.fileName
    } 
    handleToastMessage(response.data);
    yield put(doSubmitInvoiceSuccess({result: response.data, rightKey : action.payload.rightKey}));
    yield put(doDownloadFile(fileObj));
  }catch(error){
    handleToastMessage(error);
    yield put(doSubmitInvoiceError(error.response));
  }
}

export {
  handleFileUpload,
  handleFetchDocument,
  handleExtractData,
  handleExtractDataCoordinates,
  handleSubmitInvoice,
  handleFileDownload
};