const Images = {
    editIcon: require('../assets/images/edit_black_24dp.svg'),
    deleteIcon: require('../assets/images/delete_black_24dp.svg'),
    delete_key: require('../assets/images/delete_key.png'),
    page_not_found:require('../assets/images/page-not-found.png'),
    upload:require('../assets/images/Icon metro-upload.png'),
    close:require('../assets/images/Icon ionic-ios-close.png'),
    file_download:require('../assets/images/file_download.png')
}

export default Images;